import { toRem, toRems } from '../../../../../helpers/theme'

const styles = ({ theme, isSearchOpen }) => ({
    position: 'relative',
    width: '100%',

    '.searchSelectContainer': {
        width: '100%'
    },

    '.customInput': {
        boxSizing: 'content-box',
        width: '100%',
        background: '0px center',
        border: '0px',
        opacity: '1',
        outline: '0px',
        padding: '0px'
    },

    '.searchButton': {
        border: '0',
        width: '32px'
    },

    '.customInputContainer': {
        display: 'flex',
        width: '100%',
        zIndex: '1'
    },

    '.searchSelect': {
        '&__dropdown-indicator': {
            '& .searchIcon': {
                fontSize: toRem(24),

                [theme.breakpoints.up('md')]: {
                    fontSize: toRem(28)
                }
            }
        },

        '&__control': {
            padding: toRems([10, 10, 10, 22]),
            borderTopLeftRadius: toRem(100),
            borderTopRightRadius: toRem(100),
            borderBottomLeftRadius: toRem(100),
            borderBottomRightRadius: toRem(100),
            border: 0,
            outline: 0,
            lineHeight: 1,
            boxShadow: theme.shadows.deep,
            backgroundColor: theme.colors.white,
            transition: 'none',

            [theme.breakpoints.up('md')]: {
                boxShadow: isSearchOpen ? 'none' : theme.shadows.deep,
                backgroundColor: isSearchOpen ? theme.colors.gray250 : theme.colors.white
            }
        },
        '&__control--is-focused': {
            zIndex: 10,
            borderColor: 'transparent',

            '.searchSelect__dropdown-indicator': {
                '& .searchIcon': {
                    path: {
                        fill: theme.colors.pureBlack
                    }
                }
            }
        },
        '&__control--menu-is-open': {
            borderTopLeftRadius: toRem(25),
            borderTopRightRadius: toRem(25),
            borderBottomLeftRadius: toRem(0),
            borderBottomRightRadius: toRem(0),
            boxShadow: isSearchOpen ? theme.shadows.deep : 'none'
        },
        '&__value-container': {
            padding: 0
        },
        '&__placeholder': {
            fontSize: toRem(14),
            lineHeight: 1.7,
            color: theme.colors.gray500,
            [theme.breakpoints.up('md')]: {
                fontSize: toRem(16)
            },
            '&_hover, &:focus': {
                color: theme.colors.pureBlack
            }
        },
        '&__input': {
            fontSize: toRem(16),
            lineHeight: 1.7,
            input: {
                lineHeight: 'inherit',
                opacity: `1 !important`
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: toRem(16)
            }
        },
        'div[class$="Input"]': {
            display: 'flex',
            margin: 0,
            padding: 0
        },
        '&__indicators': {
            display: 'flex',
            alignItems: 'center'
        },
        '&__clear-indicator': {
            display: 'none'
        },
        '&__single-value': {
            fontSize: toRem(16),
            lineHeight: 1.7,
            [theme.breakpoints.up('lg')]: {
                fontSize: toRem(16)
            }
        },
        '&__multi-value': {
            display: 'inline-flex',
            alignItems: 'center',
            margin: toRems([1, 10, 1, 0]),
            padding: toRems([3, 10]),
            borderRadius: toRem(100),
            border: `1px solid ${theme.colors.gray300}`,
            fontSize: toRem(16),
            lineHeight: 1.7,
            [theme.breakpoints.up('md')]: {
                padding: toRems([5, 14])
            }
        },
        '&__multi-value__label': {
            padding: 0,
            fontSize: toRem(16)
        },
        '&__multi-value__remove': {
            display: 'inline-flex',
            alignItems: 'center',
            marginLeft: toRem(7),
            padding: 0,
            transition: 'color 300ms',
            '&:hover': {
                cursor: 'pointer',
                color: theme.colors.gray555
            }
        },
        '&__menu': {
            zIndex: 10,
            top: '100%',
            margin: 0,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderBottomLeftRadius: toRem(25),
            borderBottomRightRadius: toRem(25),
            boxShadow: theme.shadows.deep,
            backgroundColor: theme.colors.gray250,
            overflow: 'hidden'
        },
        '&__menu-list': {
            position: 'relative',
            padding: 0,
            '&::-webkit-scrollbar': {
                width: 0
            }
        },
        '&__menu-notice': {
            padding: toRems([10, 22]),
            fontSize: toRem(16),
            lineHeight: 1
        },
        '&__option': {
            padding: toRems([18, 22]),
            fontSize: toRem(16),
            lineHeight: 1,
            cursor: 'pointer',
            transition: 'color 300ms',
            backgroundColor: 'transparent'
        },
        '&__option--is-selected': {
            margin: 0,
            color: theme.colors.black,
            padding: toRems([18, 22]),
            backgroundColor: theme.colors.white
        },
        '&__option--is-focused': {
            fontWeight: theme.typography.fontWeight.bold,
            color: theme.colors.black,
            padding: toRems([18, 22]),
            backgroundColor: theme.colors.white
        },
        '&__indicator-separator': {
            display: 'none'
        }
    }
})

export { styles }
